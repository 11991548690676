import React from "react"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import TestimonialsListing from "./TestimonialsListing"
import TestimonialsSlider from "./TestimonialsSlider"
import { useStaticQuery, graphql } from "gatsby"

const Testimonials = ({testimonials:{type,title}}) => {
  const data = useStaticQuery(graphql`
    query Testimonials{
      allWpTestimonial {
        nodes {
          TestimonialsCPT {
            avatar {
              srcSet
              altText
              mediaItemUrl
              mediaDetails {
                width
                height
              }
            }
            name
            projectLogo {
              srcSet
              altText
              mediaItemUrl
            }
            review
            positionAndCompany
            videoLink
            testimonialOrder
          }
        }
      }
    }
  `)
  const {allWpTestimonial:{nodes}}=data
  switch (type) {
    case "Slider":
      return <TestimonialsSlider testimonials={nodes} title={title}/>
    case "Listing":
      return <TestimonialsListing testimonials={nodes} title={title} />
    default:
      break
  }

}

export default Testimonials
